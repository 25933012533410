.programmes {
  padding: 0 !important;
  overflow: hidden;
  /*    position: relative; */
}

.programmes-title {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.programme-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  /*  height: 442px;*/
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 17px 15px 35px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.programme-card__link {
  text-decoration: none !important;
}

.programme-card__img {
  width: 100%;
  /*   height: 100%;*/
  position: absolute;
  top: -13px;
  left: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.9;
  transition: all 0.2s ease-out;
  z-index: 5;
}

.programme-card__img2 {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
}

.programme-card__logo {
  max-width: 100%;
  color: #fff;
  z-index: 10;
}

.programme-card__logo img {
  display: block;
  max-width: 70%;
  max-height: 35px;
}

.programme-card__header {
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 16px 18px;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 576px) {
  .programme-card__body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .programme-card__header {
    height: 100px;
  }

  .programme-card__title {
    font-size: 1.7rem !important;
    line-height: 1.1 !important;
  }

  .programme-card__title > .small,
  .programme-card__body > h6 {
    font-size: 1.2rem !important;
  }
}

@media only screen and (min-width: 577px) {
  .programme-card__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 140px;
    padding: 10px 15px !important;
  }

  .programme-card__header {
    height: 110px;
  }

  .programme-card__body > h6 {
    line-height: 1.1 !important;
  }
}

.programme-card__title {
  margin: 0 0 6px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 20px;
  color: #000;
}

.programme-card__duration,
.programme-card__copy {
  line-height: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.programme-card__duration {
  margin: 0 0 16px;
  color: #161616;
}

.programme-card__copy {
  color: #636363;
  font-size: 14px;
  line-height: 1.3em !important;
  text-align: justify;
  /*  display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;*/
}
/*
.programme-card__link {
    display: inline-block;
    margin-top: auto;
    font-family: 'proxima nova', sans-serif;
    font-weight: 700;
    line-height: 22px;
    color: #000;
    transition: color .2s ease-out;
}*/

.programme-card__link:hover {
  color: #000;
  text-decoration: none;
}

.programme-card__link-arrow {
  width: 13px;
  height: 6px;
  margin-left: 8px;
  transition: color 0.2s ease-out;
}

.programmes .swiper-container {
  width: 100%;
  overflow: visible;
  padding: 5px 10px 20px 10px;
}

.programmes .swiper-slide {
  /*  width: 276px; */
  /* padding-bottom:10px; */
  padding: 10px;
}

.programmes .swiper-pagination,
.testimonials .swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.programmes .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #161616;
  opacity: 1;
}

.programmes .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  background-color: var(--accent-color);
  opacity: 1;
}

.programmes .swiper-scrollbar {
  display: none;
  background-color: #d8d8d8;
  border-radius: 6px;
}

.programmes .swiper-container-horizontal > .swiper-scrollbar {
  width: 70%;
  left: 15%;
}

.programmes .swiper-scrollbar-drag {
  height: 9px;
  background-color: var(--accent-color);
  top: -50%;
  border-radius: 6px;
}

@media only screen and (min-width: 768px) {
  .programmes {
    padding: 75px 0px 90px;
    background-color: #f6f6f6;
  }
  /*
    .programmes:after {
        content: none;
    }*/
}

@media only screen and (min-width: 768px) {
  .programme-card:hover {
    transform: translateX(6px) scale(0.98);
    box-shadow: 6px 6px 11px 0 rgba(0, 0, 0, 0.23),
      0 6px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .programme-card:hover .programme-card__img {
    transform: scale(1.1);
  }
  .programme-card__link:hover {
    text-decoration: none;
    color: #000;
  }
  .programme-card:hover .programme-card__link {
    color: var(--accent-color);
  }
  .programme-card:hover .programme-card__link-arrow {
    fill: var(--accent-color);
    transform: translateX(4px);
  }
  .programmes .swiper-pagination {
    display: none;
  }
  .programmes .swiper-scrollbar {
    display: block;
  }
}

.mycard_body {
  min-height: 190px;
}

/*  */
h6.text-highlights {
  line-height: 1.5 !important;
}

.programmes-single {
  position: relative;
  width: 100%;
  display: block;
  z-index: 1;
  padding: 20px 0 20px;
  -webkit-box-shadow: -2px 2px 15px -12px rgba(0,0,0,0.77); 
  box-shadow: -2px 2px 15px -12px rgba(0,0,0,0.77);
}

.programmes-single__content {
  padding: 20px 0;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  /* color: #ffffff; */
  text-decoration: none;
}

.programmes-single__content:hover {
  text-decoration: none !important;
}





@media only screen and (min-width: 768px) {
  .programmes-single__wrapper {
    width: 100%;
    /* min-height: 280px; */
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .programmes-single__background {
    position: relative;
    width: 40%;
  }

  .programmes-single__background img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right top;
  }
}

.programmes-single__title {
  margin: 0 0 6px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 20px;
  color: #000;
}

@media only screen and (max-width: 767px) {
  .span-price {
    margin-top: 5px;
    display: block;
  }
  .programmes-single__content {
    position: relative;
    flex-direction: column-reverse;
    padding: 20px;
    width: 100%;
  }
  .programmes-single__wrapper {
    position: relative;
    width: 100%;
    padding: 20px 0;
  }

  .programmes-single__background {
    position: relative;
    /* height: 280px; */
    overflow: hidden;
  }
  
  .programmes-single__background img{
    width: 100%;
    object-fit: cover;
  }
}
