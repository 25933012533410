@import "../../scss/helpers/resolutions";
@import "../../scss//common/colors";
@import "../../scss/helpers/mixins";

#news-section {
  overflow: hidden;
}
.blogHomeBox {
  // background: red;
  margin: 0 auto;
  overflow: hidden;

  @include eq-mobile {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @include at-desktop {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  &__wrapper {
    padding: 20px;
    overflow: hidden;
  }

  &__title {
    @include eq-mobile {
      padding-left: 20px;
    }
    .title {
      color: $darkblue !important;
      margin-bottom: 20px;

      @include at-tablet {
        text-align: center;
        font-size: em(42px) !important;
      }
    }
  }

  .swiper-container {
    @include eq-mobile {
      padding: 0px 20px 40px;
    }
    @include at-desktop {
      padding: 10px 10px 40px;
    }
  }
}
