
.mnemo-callout-success {
  padding: 1.25rem; 
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;  
  border-left-color: #009c65;
}

.mnemo-callout-warning {
  padding: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;  
  border-left-color: #a66300;
}

.mnemo-callout-info {
  padding: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;  
  border-left-color: #979899;
}

.mnemo-callout-danger {
  padding: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;  
  border-left-color: #d9364f;;
}

.mnemo-callout-primary {
  padding: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;  
  border-left-color: #06c;
}