@import '../../scss/helpers/resolutions';
@import '../../scss//common/colors';
@import '../../scss/helpers/mixins';

.cardNews
{
    position: relative;
    display: block;
    background: $white;
    overflow: hidden;
    border-radius: 15px;
    // border: 1px solid $grey;
    min-height: 496px;
    text-decoration: none;
    color: $dark;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: box-shadow 0.5s ease-in-out;

    &:hover
    {
        text-decoration: none;
        color: $dark;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }



    &__image
    {
        position: relative;
        width: 100%;
        height: 200px;
        overflow: hidden;
        img
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left center;
        }
    }

    &__content
    {
        padding: 20px 20px 40px;
        min-height: 280px;

    }

    &__action
    {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
}