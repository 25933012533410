//##########################################//
//                                          //
//      VARIABILI CROMATICHE SITO           //
//                                          //
//##########################################//

// Default
//--------------------------------------------------------------------///
$black: #000000;
$white: #ffffff;

// Design System
//--------------------------------------------------------------------//
// $primary:     #0066BB;
// $secondary:   #002945;
// $light:       #FAFAFA;
// $line:        #D8D8D8;
// $medium:      #a9a9a9;
// $dark:        #666666;
// $grey:        #8A959C;
// $red:         #D53D0B;
// $green:       #198754;

$darkblue: #17324d;
$mediumBlue: #23596c;
$lightBlue: #557786;
$dark: #18171c;
$grey: #d8d9d9;
$lightGrey: #f6f6f6;
// $yellow: #ffdf2c;
$yellow: #e7c000;
$purpleRed: #a12f49;
